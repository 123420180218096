import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Content from '@solid-ui-blocks/Content/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes, normalizeContentfulIndustryNodes } from '@blocks-helpers'
import styles from './_styles'

const Education = props => {
  const { allBlockContent, allContentfulIndustry } = props.data
  const templateContent = normalizeBlockContentNodes(allBlockContent?.nodes)
  const contentfulContent = normalizeContentfulIndustryNodes(allContentfulIndustry?.nodes)

  return (
    <Layout {...props}>
      <Seo title={contentfulContent.hero.text[0].text} description={contentfulContent.hero.text[1].text} />
      {/* Modals */}
      <ModalWithTabs content={templateContent['authentication']} reverse />
      <ModalWithTabs content={templateContent['contact']} />
      <ModalSimple content={templateContent['advertisement']} />
      {/* Blocks */}
      <Header content={templateContent['header']} />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={contentfulContent.hero} reverse />
      </Container>
      <Divider space='5' />
      <Content content={contentfulContent.contentOne} />
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='wide' sx={styles.contentTwoContainer}>
        <Content content={contentfulContent.contentTwo} />
      </Container>
      <Divider space='5' />
      <Footer content={templateContent['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageEducationContent {
    allBlockContent(
      filter: { page: { in: ["shared"] } }
    ) {
      nodes {
        ...BlockContent
      } 
    }
    allContentfulIndustry(filter: {slug: {eq: "small-businesses"}}) {
      nodes {
        identifier: slug
        page: slug
        title
        subtitle
        secondaryTitle
        secondarySubtitle
        secondaryContentOne {
          secondaryContentOne
        }
        secondaryContentTwo {
          secondaryContentTwo
        }
       tertiaryTitle
       tertiarySubtitle
       tertiaryContentOne {
         tertiaryContentOne
        }
       tertiaryContentTwo {
         tertiaryContentTwo
        }
        hero {
          url 
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`

export default Education
